<template>
  <section class="welcome-box xa-view" style="background-color:#fff;">
    <section class="xa-flex xa-view container"></section>
    <AppCopyright></AppCopyright>
  </section>
</template>
<script>
import AppCopyright from '@/components/AppCopyright'
export default {
  components: {
    AppCopyright
  }
}
</script>
<style scoped>
.welcome-box {
  height: 100%;
}
.container {
  margin: 0 auto;
  width: 100%;
  /* background: url(../assets/logo_min.png); */
  background: url(../assets/logo_dark.svg);
  background-size: 320px;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.4;
}
</style>
