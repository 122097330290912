<template>
  <div style="margin: 10px 0px;color: #999;text-align:center;">
    <p style="font-size: 12px;line-height:24px;">{{ version }}</p>
    <p style="font-size: 14px;line-height:24px;">© {{ year }} XAG</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      year: new Date().getFullYear()
    }
  }
}
</script>
